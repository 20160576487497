export const persistCheckout = (data: any, property: string) => {
  // Obtener el estado actual de persist:checkout
  const currentData =
    JSON.parse((localStorage as any).getItem("persist:checkout")) || {};

  // Actualizar el estado con el nuevo plan
  const updatedData = {
    ...currentData,
    [property]: data,
  };

  // Guardar el estado actualizado en localStorage
  localStorage.setItem("persist:checkout", JSON.stringify(updatedData));
};

export const removePropertyFromCheckout = (property: string) => {
  // Obtener el estado actual de persist:checkout
  const currentData =
    JSON.parse((localStorage as any).getItem("persist:checkout")) || {};

  // Eliminar la propiedad especificada
  if (currentData.hasOwnProperty(property)) {
    delete currentData[property];
  }

  // Guardar el estado actualizado en localStorage
  localStorage.setItem("persist:checkout", JSON.stringify(currentData));
};
