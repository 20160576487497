"use client";
import { persistCheckout } from "@/utils/persistCheckout";
import { StarIcon } from "../Icons";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { getIp } from "@/utils/getCountryIp";

interface PlansProps {
  products: any;
}

export default function Plans({ products }: PlansProps) {
  const router = useRouter();
  const [country, setCountry] = useState<string | null>(null);

  const selectPlan = (plan: any) => {
    persistCheckout(plan, "cart");
    if (country === "CL" || country === null) {
      persistCheckout(plan.price, "totalCart");
    } else {
      persistCheckout(plan.usd_price, "totalCart");
    }
    router.push("/payment");
  };

  useEffect(() => {
    const initializeCountry = async () => {
      const resIp = await getIp();

      setCountry(resIp);
    };
    initializeCountry();
  }, []);

  return (
    <div className="flex justify-center items-center flex-col px-5 text-center gap-8 mt-20">
      {
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          <div className="relative border-2 border-deep-purple rounded-3xl order-2 md:order-1">
            <div className="px-5 py-16">
              <span className="font-black text-5xl block">
                $
                {country === "CL" || country === null
                  ? products[1].price.toLocaleString("es-CL")
                  : products[1].usd_price}
              </span>
              <span className="font-medium text-2xl">MENSUAL</span>
            </div>
            <div className="bg-orange py-2 text-cream inline-flex w-full justify-center gap-5">
              <StarIcon className="w-8 h-8 fill-cream" />
              <span className="text-2xl">PLAN MENSUAL</span>
              <StarIcon className="w-8 h-8 fill-cream" />
            </div>
            <div className="p-10">
              <span className="font-bold text-2xl">INCLUYE</span>
              <ul className="mt-5 text-left text-lg font-light list-disc [&>*]:ml-5">
                <li>Plan de Entrenamiento</li>
                <li>Plan Nutricional personalizado</li>
                <li>Recetas saludables</li>
                <li>Acceso ilimitado a la plataforma</li>
                <li>Clases en Vivo y On Demand</li>
                <li>Clases nuevas todas las semanas</li>
                <li>Acceso a más de 500 clases/programas</li>
                <li>Guarda tus clases favoritas</li>
                <li>Dispositivos Multiplataforma</li>
                <li>Entrena donde y cuando quieras</li>
                <li>Soporte online</li>
              </ul>
            </div>
            <div className="p-5">
              <button
                onClick={() => selectPlan(products[1])}
                className="px-8 py-2 bg-transparent border border-deep-purple rounded-full hover:text-cream hover:bg-bright-pink hover:border-bright-pink transition-all duration-150"
              >
                LO QUIERO
              </button>
            </div>
          </div>
          <div className="relative border-2 border-deep-purple rounded-3xl order-1 md:order-2">
            <div className="absolute bg-bright-pink w-fit px-5 py-1 rounded-full text-cream -top-12 left-1/2 -translate-x-1/2">
              <span>MEJOR PRECIO</span>
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 bg-deep-purple w-4/5 mx-auto rounded-b-xl py-0.5">
              <span className="text-cream font-medium">
                AHORRAS{" "}
                {country === "CL" || country === null
                  ? "CLP $84.000"
                  : "USD $84"}
              </span>
            </div>
            <div className="px-5 py-12">
              <span className="font-black text-5xl block">
                $
                {country === "CL" || country === null
                  ? (products[0].price / 12).toLocaleString("es-CL")
                  : products[0].usd_price / 12}
              </span>
              <span className="font-medium text-2xl">MENSUAL</span>
              <span className="block">Pagas 12 meses por adelantado</span>
            </div>
            <div className="bg-bright-pink py-2 text-cream inline-flex w-full justify-center gap-5">
              <StarIcon className="w-8 h-8 fill-cream" />
              <span className="text-2xl">PLAN ANUAL</span>
              <StarIcon className="w-8 h-8 fill-cream" />
            </div>
            <div className="p-10">
              <span className="font-bold text-2xl">INCLUYE</span>
              <ul className="mt-5 text-left text-lg font-light list-disc [&>*]:ml-5">
                <li>Plan de Entrenamiento</li>
                <li>Plan Nutricional personalizado</li>
                <li>Recetas saludables</li>
                <li>Acceso ilimitado a la plataforma</li>
                <li>Clases en Vivo y On Demand</li>
                <li>Clases nuevas todas las semanas</li>
                <li>Acceso a más de 500 clases/programas</li>
                <li>Guarda tus clases favoritas</li>
                <li>Dispositivos Multiplataforma</li>
                <li>Entrena donde y cuando quieras</li>
                <li>Soporte online</li>
              </ul>
            </div>
            <div className="p-5">
              <button
                onClick={() => selectPlan(products[0])}
                className="px-8 py-2 bg-transparent border border-deep-purple rounded-full hover:text-cream hover:bg-bright-pink hover:border-bright-pink transition-all duration-150"
              >
                LO QUIERO
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
